<template>
  <div class="article-performing-card">
    <div class="article-performing-card__header">
      <h2>Best Performing Impact Article</h2>
    </div>
    <div class="article-performing-card__body">
      <div class="header">
        <div class="title">
          <h4>Olympics 2021 - SOP's Being strictly followed</h4>
        </div>
        <div class="total">
          <p>Total Likes</p>
          <p>527</p>
        </div>
        <div class="total">
          <p>Total Reads</p>
          <p>775</p>
        </div>
      </div>
      <div class="body">
        <div class="left">
          <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</h4>
        </div>
        <div class="right">
          <p>Go to details</p>
          <button>Go to Article</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include reset-css;

.article-performing-card {
  width: 100%;
  margin-bottom: 2.5rem;
  &__header {
    padding: 1.5rem;
    background-color: $color-faded-blue;
    margin-bottom: 1.3rem;
    h2 {
      font-size: 1.8rem;
      font-family: $font-secondary-bold;
      color: $color-white;
    }
  }
  &__body {
    padding: 2rem 1.5rem;
    background-color: $color-white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.3rem;
      .title {
        font-size: 1.8rem;
        font-family: $font-secondary-bold;
        width: 150%;
        border-bottom: 0.24rem solid rgba(128, 128, 128, 0.137);
        padding-bottom: 1rem;
        padding-right: 2rem;
      }
      .total {
        width: 50%;
        margin-left: 1rem;
        p {
          font-size: 1.2rem;
          font-family: $font-secondary;
          margin-bottom: 0.5rem;
        }
      }
    }
    .body {
      display: flex;
      align-items: flex-start;
      .left {
        font-size: 1.4rem;
        font-family: $font-secondary;
        width: 80%;
      }
      .right {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-size: 1.4rem;
          font-family: $font-primary-bold;
        }
        button {
          border: none;
          font-size: 1.2rem;
          font-family: $font-primary-semibold;
          width: 14rem;
          padding: 1rem 0;
          cursor: pointer;
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
}
</style>