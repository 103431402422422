<template>
  <div class="financial-dashboard">
    <the-header></the-header>
    <a-layout>
      <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
        <div class="logo" />
        <a-menu theme="dark" mode="inline" v-model:selectedKeys="selectedKeys">
          <div class="menu-icon" @click="() => (collapsed = !collapsed)" :class="{open: collapsed}">
            <div class="icon">
              <i class="icon icon-forward"></i>
            </div>
          </div>
          <a-menu-item key="1" @click="setActive('dashboard')">
            <i class="icon icon-speedometer"></i>
            <p>Dashboard</p>
          </a-menu-item>
          <a-menu-item key="2" @click="setActive('articles')">
            <i class="icon icon-speedometer"></i>
            <p>Articles</p>
          </a-menu-item>
          <a-menu-item key="3" @click="setActive('fundraisers')">
            <i class="icon icon-star_badge"></i>
            <p>Fundraisers</p>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-content>
          <keep-alive>
            <component :is="active"></component>
          </keep-alive>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import theHeader from "../layout/ProfilePageHeader.vue";
import Dashboard from "../FinancialDashboard/Dashboard.vue";
import Articles from "../FinancialDashboard/Articles.vue";
import Fundraisers from "../FinancialDashboard/Fundraisers.vue";

export default {
  components: {
    theHeader,
    Articles,
    Dashboard,
    Fundraisers,
  },
  data() {
    return {
      active: null,
      collapsed: true,
    };
  },
  mounted() {
    this.active = Articles;
  },
  methods: {
    setActive(screen) {
      this.active = 
        screen == 'articles' ? Articles:
        screen == 'fundraisers' ? Fundraisers:
        Dashboard;
    },
  },
};
</script>

<style lang="scss">
.financial-dashboard {
  .ant-layout-content{
    margin: 3.3rem 5rem !important;
  }
  .ant-layout-has-sider {
    min-height: calc(100vh - 7.93rem);
    #components-layout-demo-custom-trigger {
      .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
      }
      .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.3);
        margin: 16px;
      } 
    }
    .site-layout .site-layout-background {
      background: #fff;
    }
    .ant-menu-dark .ant-menu-item-selected {
      border-right: 0.5rem solid $color-primary;
    }
    .ant-menu-dark .ant-menu-item-selected:first-child {
      border-right: none;
    }
    .ant-menu-item {
      margin: 0 auto !important;
      padding: 0 !important;
      height: 5rem;
      margin-bottom: 3rem !important;

      .ant-menu-title-content {
        flex-direction: column;
        display: flex;
        align-items: center;
        margin: 0 auto;

        .icon {
          font-size: 2.8rem;
        }
        p {
          margin: 0;
          line-height: 2rem;
          font-size: 1.1rem;
          font-family: $font-primary;
        }
      }
      
    }
    .ant-menu-item:first-child {
      margin-bottom: 2rem !important;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
      .ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
  .ant-layout-content {
    margin: 3.3rem;
    padding: 0;
    padding-bottom: 5.5rem;
  }
  .menu-icon{
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    .icon{
      color: black;
    }
  }
  .open{
    transform: rotate(180deg);
  }
}
</style>
