<template>
  <div class="user-list">
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/Selena-Gomez-Square.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Selena Gomez</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Collier Rhea</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/Selena-Gomez-Square.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Selena Gomez</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Collier Rhea</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/Selena-Gomez-Square.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Selena Gomez</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Collier Rhea</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/Selena-Gomez-Square.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Selena Gomez</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Collier Rhea</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
    <div class="user">
      <div class="user__left">
        <div class="user-img">
          <img src="../../assets/images/Selena-Gomez-Square.webp" alt="User" />
        </div>
        <div class="user-name">
          <h4>Selena Gomez</h4>
          <p>New York, United States</p>
        </div>
      </div>
      <div class="user__right">
        <button class="message">Message</button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.user-list {
  height: 40rem;
  overflow-y: scroll;
  margin-top: 1.6rem;
  padding-right: 5rem;
  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    border-bottom: 0.24rem solid #7070700c;
    &__left {
      display: flex;
      align-items: center;
      .user-img {
        width: 4.7rem;
        img {
          width: 100%;
          border-radius: 100%;
        }
      }
      .user-name {
        margin-left: 1rem;
        h4 {
          font-size: 1.6rem;
          font-family: $font-primary-semibold;
          margin-bottom: 0;
        }
        p {
          font-size: 1.2rem;
          font-family: $font-primary;
          margin-bottom: 0;
          cursor: pointer;
        }
      }
    }
    &__right {
      .message {
        height: 4rem;
        font-size: 1.3rem;
        border: 0.23rem solid $color-primary;
        color: $color-primary;
        padding: 0 3rem;
        font-family: $font-primary-semibold;
        background-color: transparent;
        cursor: pointer;
        transition: 0.3s;
        margin-left: 0.5rem;
      }
      .message:hover {
        color: $color-white;
        background-color: $color-primary;
        border-color: $color-white;
      }
    }
  }
}
.user-list::-webkit-scrollbar {
  width: 0.5rem;
}

.user-list::-webkit-scrollbar-track {
  background-color: #c8c8c87d;
  border-radius: 0.8rem;
}

.user-list::-webkit-scrollbar-thumb {
  background-color: $color-primary;
  border-radius: 0.8rem;
  height: 5rem;
}
</style>