<template>
  <div class="patron-article">
    <div class="patron-article__header">
      <h1>Patron Articles</h1>
      <a href="" class="active">Discover</a>
      <a href="">Impact</a>
    </div>

    <div class="patron-article__body">
      <a-collapse
        v-model="activeKey"
        :expandIcon="expandIcon"
        :expand-icon-position="'right'"
        :bordered="false"
      >
        <a-collapse-panel key="1" header="Finance">
          <a-row :gutter="[18, 18]" class="articles-section">
            <a-col
              @click="showDrawer = true"
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
              v-for="article in articles"
              :key="article.id"
            >
              <article-card
                :title="article.title"
                :author-name="article.authorName"
                :text="article.text"
                :image="article.image"
                :profile-photo="article.profilePhoto"
              ></article-card>
            </a-col>
          </a-row>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="Finance">
          <a-row :gutter="[18, 18]" class="articles-section">
            <a-col
              @click="showDrawer = true"
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
              v-for="article in articles"
              :key="article.id"
            >
              <article-card
                :title="article.title"
                :author-name="article.authorName"
                :text="article.text"
                :image="article.image"
                :profile-photo="article.profilePhoto"
              ></article-card>
            </a-col>
          </a-row>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="Finance">
          <a-row :gutter="[18, 18]" class="articles-section">
            <a-col
              @click="showDrawer = true"
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
              v-for="article in articles"
              :key="article.id"
            >
              <article-card
                :title="article.title"
                :author-name="article.authorName"
                :text="article.text"
                :image="article.image"
                :profile-photo="article.profilePhoto"
              ></article-card>
            </a-col>
          </a-row>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
  <a-drawer
    placement="right"
    :closable="true"
    :visible="showDrawer"
    @close="showDrawer = false"
    width="46rem"
    wrapClassName="article-financial-analysis"
  >
    <div class="article-financial-analysis__header">
      <h2>Article Financial analysis</h2>
      <div class="navigation">
        <span
          @click="select('financial-analysis')"
          :class="{ active: isActive('financial-analysis') }"
          >Financial Analysis</span
        >
        <span
          @click="select('purchasers')"
          :class="{ active: isActive('purchasers') }"
          >Purchasers</span
        >
      </div>
    </div>
    <div class="article-financial-analysis__body">
      <div
        v-if="selectedTab == 'financial-analysis'"
        class="financial-analysis"
      >
        <div class="stats-card-main">
          <div class="one">
            <stats-card></stats-card>
          </div>
          <div class="two">
            <stats-card></stats-card>
            <stats-card></stats-card>
          </div>
          <div class="two">
            <stats-card></stats-card>
            <stats-card></stats-card>
          </div>
        </div>
        <button>Withdraw payments</button>
        <div class="description">
          <h4>Withdrawal Disclaimer</h4>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </p>
        </div>
      </div>
      <div v-else-if="selectedTab == 'purchasers'" class="purchasers">
        <list-of-donors></list-of-donors>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import ArticleCard from "../BaseComponents/ArticleCard.vue";
import ListOfDonors from "./ListOfDonors.vue";

import StatsCard from "./StatsCard.vue";

export default {
  components: {
    ArticleCard,
    StatsCard,
    ListOfDonors,
  },
  data() {
    return {
      activeKey: ["1"],
      articles: [
        {
          id: 1,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
        {
          id: 2,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
        {
          id: 3,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
        {
          id: 4,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
      ],
      showDrawer: false,
      selectedTab: "financial-analysis",
    };
  },
  methods: {
    expandIcon(props) {
      let icon;
      if (props.isActive) {
        icon = <i class="icon icon-forward down"></i>;
        return (
          <a class="expand-row-icon">
            <span class="expand-icon-text">Collapse</span>
            <span class="expand-icon">{icon}</span>
          </a>
        );
      } else {
        icon = <i class="icon icon-forward up"></i>;
        return (
          <a class="expand-row-icon">
            <span class="expand-icon-text">Expand</span>
            <span class="expand-icon">{icon}</span>
          </a>
        );
      }
    },
    select(tab) {
      this.selectedTab = tab;
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
  },
};
</script>
<style lang="scss">
@include reset-css;

.patron-article {
  &__header {
    display: flex;
    align-items: center;
    h1 {
      font-size: 2.4rem;
      font-family: $font-primary-bold;
      margin-right: 3rem;
    }
    a {
      font-size: 1.6rem;
      font-family: $font-secondary;
      margin-right: 2rem;
      color: $color-black;
      opacity: 0.8;
      position: relative;
    }
    a:hover {
      color: $color-black;
    }
    .active {
      font-family: $font-secondary-bold;
      opacity: 1;
    }
    .active::before {
      content: "";
      width: 2.8rem;
      height: 0.4rem;
      background-color: $color-primary;
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      opacity: 1;
    }
  }
  &__body {
    border: none;
    background-color: unset;
    .articles-section {
      margin-top: 2rem;
    }
    .ant-collapse {
      background-color: transparent;
      .ant-collapse-item {
        border-bottom: none !important;
        background-color: transparent;
        margin-bottom: 1rem;
        .ant-collapse-header {
          padding: 2rem 0 !important;
          font-size: 2.2rem;
          font-family: $font-secondary-bold;
          .ant-collapse-arrow {
            background-color: #f0f2f5;
            right: 0;
            .icon {
              font-size: 1.6rem;
            }
            .up {
              transform: rotate(90deg);
            }
            .down {
              transform: rotate(-90deg);
            }
          }
          &::before {
            content: "";
            width: 86%;
            height: 1px;
            margin: 0 10rem;
            background-color: $color-dark-grey-1;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @include respond(tab-port) {
              width: 69%;
            }
          }
        }
        .ant-collapse-content {
          border: none;
        }
      }
    }
  }
}
.expand-icon-text {
  margin: 0 2rem;
  position: relative;
}
.expand-icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.article-financial-analysis {
  .ant-drawer-body {
    padding: 0;
    padding-top: 7rem;
  }
  &__header {
    padding: 4rem;
    padding-top: 0;
    padding-bottom: 2rem;
    h2 {
      font-size: 2.4rem;
      font-family: $font-secondary-bold;
      font-weight: 900;
    }
    .navigation {
      margin-top: 2.5rem;
      span {
        cursor: pointer;
        font-size: 1.8rem;
        font-family: $font-secondary;
        opacity: 0.5;
        color: $color-black;
        margin-right: 2.5rem;
      }
      .active {
        font-family: $font-secondary-bold;
        opacity: 1;
      }
    }
  }
  &__body {
    padding-top: 0;
    padding-right: 4rem;
    .financial-analysis {
      height: 68rem;
      overflow: auto;
      padding-right: 2.5rem;
      padding-top: 2rem;
      padding-left: 4rem;
      .stats-card-main {
        .stats-card {
          margin-bottom: 2rem;
        }
        .two {
          //  display: flex;
          gap: 2rem;
        }
      }
      button {
        border: none;
        font-size: 1.8rem;
        font-family: $font-secondary-bold;
        width: 100%;
        padding: 1.6rem 0;
        cursor: pointer;
        background-color: #001b26;
        color: $color-white;
        margin-top: 1.5rem;
      }
      .description {
        margin-top: 2.7rem;
        h4 {
          font-size: 1.8rem;
          font-family: $font-secondary-bold;
          margin-bottom: 1rem;
        }
        p {
          font-size: 1.4rem;
          font-family: $font-secondary;
          opacity: 0.8;
        }
      }
    }
    .financial-analysis::-webkit-scrollbar {
      width: 0.5rem;
    }

    .financial-analysis::-webkit-scrollbar-track {
      background-color: #c8c8c87d;
      border-radius: 0.8rem;
    }

    .financial-analysis::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 0.8rem;
      height: 5rem;
    }
    .purchasers {
      padding-left: 4rem;
      // padding-top: 2rem;

      .user-list {
        padding-right: 0;
        padding-right: 2.5rem;
        height: 68rem;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
}
</style>