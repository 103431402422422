<template>
  <div class="fundraiser-card" @click="setShowDarwer">
    <div class="fundraiser-card__image">
      <img
        :src="require(`../../assets/images/${image}`)"
        alt=""
        class="image"
      />
    </div>
    <div class="body">
      <div class="fundraiser-card__header">
        <h2 class="fundraiser-card__heading fundraiser-card-heading">
          {{ title }}
        </h2>
      </div>
      <div class="fundraiser-card__body">
        <p class="donation">Donation Count</p>
        <div class="count-bar">
          <div class="grey">
            <div class="green"></div>
          </div>
        </div>
        <p class="count-no">$256 out of $500</p>
      </div>
      <div class="fundraiser-card__footer">
        <div class="posted-by">
          <p class="credits">Posted by</p>
          <div class="author">
            <div class="profile-photo">
              <img
                :src="require(`../../assets/images/${profilePhoto}`)"
                alt=""
                class="image"
              />
            </div>
            <p class="author-name">
              {{ authorName }}
            </p>
            <i class="icon icon-detail icon-info_circle"></i>
          </div>
          <div class="like">
            <i class="icon icon-like icon-thumbs_up"></i>
          </div>
        </div>
        <div class="fundraiser-heroes">
          <div class="fundraiser-heroes__header">
            <p>Fundraiser Super Heroes</p>
          </div>
          <div class="fundraiser-heroes__body">
            <div class="heroes">
              <div class="heroes-image image-1">
                <img
                  :src="require(`../../assets/images/${profilePhoto}`)"
                  alt=""
                />
              </div>
              <div class="heroes-image image-2">
                <img
                  :src="require(`../../assets/images/${profilePhoto}`)"
                  alt=""
                />
              </div>
              <div class="heroes-image image-3">
                <img
                  :src="require(`../../assets/images/${profilePhoto}`)"
                  alt=""
                />
              </div>
              <div class="heroes-image image-4">
                <img
                  :src="require(`../../assets/images/${profilePhoto}`)"
                  alt=""
                />
              </div>
              <div class="heroes-image image-5">
                <img
                  :src="require(`../../assets/images/${profilePhoto}`)"
                  alt=""
                />
              </div>
              <div class="heroes-image image-6">
                <img
                  :src="require(`../../assets/images/${profilePhoto}`)"
                  alt=""
                />
              </div>
              <div class="heroes-image image-7">
                <img
                  :src="require(`../../assets/images/${profilePhoto}`)"
                  alt=""
                />
              </div>
              <p>20+</p>
            </div>
            <div class="award">
              <i class="icon icon-star_badge"></i>
            </div>
          </div>
          <div class="fundraiser-heroes__footer">
            <button>Donate Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <donations-received
    :show-drawer="showDarwer"
    @close-drawer="showDarwer = false"
    @show-modal="showDonationModal = true"
  ></donations-received>

  <a-modal
    v-model:visible="showDonationModal"
    :footer="null"
    wrapClassName="donate-modal"
    width="88rem"
  >
    <div class="modal">
      <div class="modal__header">
        <div class="navigation">
          <div class="logo">
            <img src="../../assets/images/Amblem2.webp" alt="" />
          </div>
          <span @click="select('money')" :class="{ active: isActive('money') }"
            >Donate Money</span
          >
          <span
            @click="select('efforts')"
            :class="{ active: isActive('efforts') }"
            >Donate Efforts</span
          >
        </div>
      </div>
      <div v-if="selectedTab == 'money'" class="modal__body">
        <div class="description">
          <h2>Donate to make a change</h2>
          <p>
            When you support Getup, you're powering a million-strong
            strategically savvy authors that makes real change happen.
          </p>
        </div>
        <a-row>
          <a-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="left">
              <div class="money-donate-offer">
                <p class="active">$10</p>
                <p>$15</p>
                <p>$20</p>
                <p>$30</p>
                <p>$35</p>
                <p>$40</p>
                <p>$50</p>
                <p>$<span>Offer Money</span></p>
              </div>
              <a-checkbox @change="onChange"
                ><p>
                  Make my gift go further - I'll donate an additional $0.20 to
                  help with processing fee.
                </p></a-checkbox
              >
              <div class="all-input">
                <p>Email Address*</p>
                <input type="text" placeholder="You@email.com" />
              </div>
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="right">
              <div class="all-input">
                <div class="payment-card">
                  <p>Card Number</p>
                  <div class="all-payment-card">
                    <div class="payment-card-img">
                      <img
                        src="../../assets/images/master-card.webp"
                        alt="Master Card"
                      />
                    </div>
                    <div class="payment-card-img">
                      <img
                        src="../../assets/images/visa-card.webp"
                        alt="Master Card"
                      />
                    </div>
                    <div class="payment-card-img">
                      <img
                        src="../../assets/images/discover-logo.webp"
                        alt="Master Card"
                      />
                    </div>
                    <div class="payment-card-img">
                      <img
                        src="../../assets/images/american-card.webp"
                        alt="Master Card"
                      />
                    </div>
                  </div>
                </div>
                <input type="text" placeholder="Enter Card number here" />
              </div>
              <div class="all-input">
                <p>Name on Card</p>
                <input type="text" placeholder="Enter Name" />
              </div>
              <div class="date">
                <div class="all-input">
                  <p>Card Expiry</p>
                  <input type="text" placeholder="Enter Expiry Date" />
                </div>
                <div class="all-input">
                  <p>CVV</p>
                  <input type="text" placeholder="Enter CVV" />
                </div>
              </div>
              <button class="btn-primary donate-btn" @click="openBadgeModal()">
                Donate Now
              </button>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-else class="modal__body donate-efforts">
        <div class="description">
          <h2>Donate to make a change</h2>
          <p>
            When you support Getup, you're powering a million-strong
            strategically savvy authors that makes real change happen.
          </p>
        </div>
        <a-row :gutter="[24]">
          <a-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="all-input">
              <p>In what capacity you will help the author.</p>
              <input type="text" placeholder="Your Capacity" />
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="all-input">
              <p>Give a one liner description of your effort</p>
              <input type="text" placeholder="Add one liner" />
            </div>
            <div class="btn">
              <button class="btn-primary donate-btn" @click="openBadgeModal()">
                Donate Now
              </button>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-modal>
  <a-modal
    v-model:visible="showBadgeModal"
    :footer="null"
    wrapClassName="badge-modal"
    width="87rem"
  >
    <div class="modal">
      <div class="modal__header">
        <div class="logo">
          <img src="../../assets/images/Amblem2.webp" alt="" />
        </div>
      </div>
      <div class="modal__body">
        <div class="description">
          <h2>You are a super hero and we are proud of you.</h2>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat.
          </p>
        </div>
        <p class="para">You earned a badge for your act of kindness.</p>
        <div class="badge-descriptoon">
          <i class="icon icon-star_badge"></i>
          <p>
            You can enable a donor badge to be shown on your profile from your
            profile settings.
          </p>
        </div>
        <button class="epb-btn btn-primary">Enable Profile Badge</button>
        <div class="badge-user user-1">
          <div class="user-image">
            <img src="../../assets/images/4159.webp" alt="" />
          </div>
          <div class="badge">
            <i class="icon icon-star_badge"></i>
          </div>
        </div>
        <div class="badge-user user-2">
          <div class="user-image">
            <img src="../../assets/images/Selena-Gomez-Square.webp" alt="" />
          </div>
          <div class="badge">
            <i class="icon icon-star_badge"></i>
          </div>
        </div>
        <div class="badge-user user-3">
          <div class="user-image">
            <img src="../../assets/images/mark.jpeg" alt="" />
          </div>
          <div class="badge">
            <i class="icon icon-star_badge"></i>
          </div>
        </div>
        <div class="badge-user user-4">
          <div class="user-image">
            <img src="../../assets/images/profile-pic.webp" alt="" />
          </div>
          <div class="badge">
            <i class="icon icon-star_badge"></i>
          </div>
        </div>
        <div class="badge-user user-5">
          <div class="user-image">
            <img src="../../assets/images/4159.webp" alt="" />
          </div>
          <div class="badge">
            <i class="icon icon-star_badge"></i>
          </div>
        </div>
        <div class="badge-user user-6">
          <div class="user-image">
            <img src="../../assets/images/gladys3.webp" alt="" />
          </div>
          <div class="badge">
            <i class="icon icon-star_badge"></i>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import DonationsReceived from "./DonationsReceived.vue";
export default {
  components: {
    DonationsReceived,
  },
  props: {
    title: String,
    authorName: String,
    image: String,
    profilePhoto: String,
  },
  data() {
    return {
      showDarwer: false,
      showDonationModal: false,
      showBadgeModal: false,
      selectedTab: "money",
    };
  },
  methods: {
    openBadgeModal() {
      this.showDonationModal = false;
      this.showBadgeModal = true;
    },
    select(tab) {
      this.selectedTab = tab;
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
    setShowDarwer() {
      this.showDarwer = true;
    },
  },
};
</script>
<style lang="scss">
.fundraiser-card {
  cursor: pointer;
  height: 100%;
  width: 100%;
  &__image {
    background-size: cover;
    .image {
      width: 100%;
    }
  }
  .body {
    padding: 1.9rem;
    background-color: $color-white;
  }
  &__header {
    // margin: 2rem 2rem 0;
    border-bottom: 1px solid rgb(135, 149, 157, 0.3);
    padding-bottom: 1rem;
  }
  &__heading {
    font-family: $font-primary-bold;
    font-size: 1.8rem;
    margin: 0;
  }
  &__body {
    width: 100%;
    .donation {
      font-family: $font-primary-bold;
      font-size: 1.1rem;
      margin: 0.9rem 0;
    }
    .grey {
      background-color: #c8c8c870;
      width: 100%;
      height: 0.5rem;
      position: relative;
      .green {
        position: relative;
        left: 0;
        background-color: #469f13;
        width: 40%;
        height: 0.5rem;
      }
    }
    .count-no {
      font-family: $font-primary;
      font-size: 1.1rem;
      margin-bottom: 0;
      opacity: 0.8;
      margin-top: 0.7rem;
      text-align: right;
    }
  }
  &__footer {
    .posted-by {
      display: flex;
      justify-content: space-between;

      border-bottom: 1px solid rgb(135, 149, 157, 0.3);
      padding: 1.5rem 0;
      flex-wrap: wrap;
      .author {
        display: flex;
        align-items: center;
        .profile-photo {
          width: 2.6rem;
          height: 2.6rem;
          margin-right: 1rem;
          position: relative;
          .image {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            position: absolute;
          }
        }
        &-name {
          margin: 0;
          opacity: 0.8;
          font-family: $font-primary-bold;
          font-size: 1.3rem;
          margin-right: 1rem;
        }
      }
      .credits {
        flex-basis: 100%;
        font-family: $font-primary;
        font-size: 1.1rem;
        opacity: 0.8;
      }
      .icon-detail {
        width: 2.2rem;
        height: 2.2rem;
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        opacity: 0.5;
        cursor: pointer;
      }
      .like {
        .icon-like {
          opacity: 0.2;
          font-size: 2.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.2rem;
          height: 2.2rem;
          cursor: pointer;
        }
      }
    }
    .fundraiser-heroes {
      &__header {
        p {
          font-family: $font-primary-bold;
          font-size: 1.1rem;
          margin: 1rem 0;
        }
      }
      &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .heroes {
          display: flex;
          // position: relative;
          align-items: center;
          .heroes-image {
            width: 2.4rem;
            height: 2.4rem;
            position: relative;
            img {
              width: 100%;
              border-radius: 100%;
              height: 100%;
              position: absolute;
            }
          }
          .image-2 {
            transform: translateX(-50%);
          }
          .image-3 {
            transform: translateX(-100%);
          }
          .image-4 {
            transform: translateX(-150%);
          }
          .image-5 {
            transform: translateX(-200%);
          }
          .image-6 {
            transform: translateX(-250%);
          }
          .image-7 {
            transform: translateX(-300%);
          }
          p {
            font-family: $font-primary;
            font-size: 1.2rem;
            margin: 0;
            opacity: 0.8;
            transform: translateX(-320%);
          }
        }
        .award {
          .icon {
            color: #ffa200;
            font-size: 2rem;
          }
        }
      }
      &__footer {
        button {
          border: none;
          font-size: 1.6rem;
          font-family: $font-primary-bold;
          width: 100%;
          height: 4.9rem;
          padding: 0.5rem 0;
          cursor: pointer;
          margin-top: 1.8rem;
          background-color: #011e29;
          color: $color-white;
        }
      }
    }
  }
}
.donate-modal {
  .modal {
    padding: 3.5rem;
    &__header {
      margin-bottom: 2rem;
      .navigation {
        margin-bottom: 2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .logo {
          width: 4.4rem;
          height: 4.8rem;
          margin-right: 3rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        span {
          font-size: 1.8rem;
          font-family: $font-secondary;
          margin-right: 2.2rem;
          color: $color-black;
          opacity: 0.5;
          cursor: pointer;
        }
        .active {
          opacity: 1;
          font-family: $font-secondary-bold;
        }
      }
    }
    &__body {
      .description {
        margin-bottom: 3.2rem;
        h2 {
          font-size: 2.5rem;
          font-family: $font-secondary-bold;
          margin-bottom: 0.2rem;
        }
        p {
          font-size: 1.6rem;
          font-family: $font-secondary;
        }
      }
      .all-input {
        p {
          font-size: 1.6rem;
          font-family: $font-secondary;
          margin-bottom: 1.7rem;
        }
        input {
          width: 100%;
          background-color: $color-white;
          font-size: 1.6rem;
          font-family: $font-secondary;
          padding: 1.2rem 2rem;
          outline: none;
          border: 0.22rem solid #70707033;
          height: 5.1rem;
        }
        input::placeholder {
          opacity: 0.5;
        }
      }
      .left {
        padding-right: 2.8rem;
        @include respond(phone-x-small) {
          padding: 0;
        }
        .money-donate-offer {
          // margin: 2.5rem 0;
          display: grid;
          grid-template-columns: 50% auto;
          gap: 1.2rem;
          p {
            background-color: $color-white;
            border: 0.22rem solid #70707033;
            width: 100%;
            padding: 1rem 0;
            font-size: 1.8rem;
            font-family: $font-secondary-bold;
            color: $color-black;
            text-align: center;
            cursor: pointer;
          }

          p:last-child {
            text-align: left;
            padding: 1rem 2rem;
          }
          .active {
            color: $color-white;
            background-color: $color-primary;
            border-color: $color-primary;
          }

          span {
            font-size: 1.6rem;
            opacity: 0.5;
            font-family: $font-secondary;
            margin-left: 1.2rem;
          }
        }
        .ant-checkbox-wrapper {
          position: relative;
          display: flex;
          margin: 3rem 0;
          margin-bottom: 4rem;
          .ant-checkbox-checked::after {
            border: none !important;
          }
          p {
            position: absolute;
            top: -0.5rem;
            font-size: 1.6rem;
            font-family: $font-secondary;
          }
        }
      }
      .right {
        padding-left: 2.8rem;
        border-left: 0.22rem solid #70707033;
        padding: 0.5rem 0;
        @include respond(phone-x-small) {
          border: none;
          padding: 0;
          padding-left: 0 !important;
        }
        .all-input {
          input {
            margin-bottom: 1.7rem;
          }
          .payment-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.7rem;
            @include respond(phone-x-small) {
              margin: 1.7rem 0;
            }
            p {
              margin-bottom: 0;
            }
            .all-payment-card {
              display: flex;
              align-items: center;
              .payment-card-img {
                width: 3rem;
                height: 100%;
                margin: 0 0.5rem;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
        .date {
          display: flex;
          .all-input {
            margin-right: 1rem;
          }
          .all-input:last-child {
            margin-right: 0;
          }
        }
      }
      .donate-btn {
        width: 100%;
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
        padding: 1.2rem 2rem;
        height: 5.1rem;
        margin-top: 4rem;
      }
    }
    .donate-efforts {
      .btn {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: flex-end;
        .donate-btn {
          width: 85%;
          @include respond(phone-x-small) {
            width: 100%;
          }
        }
      }
    }
  }
}
.badge-modal {
  .ant-modal-body {
    background-image: url(../../assets/images/blue-bg@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    .modal {
      padding: 3rem 3.5rem;
      padding-bottom: 6.4rem;
      background-color: transparent;
      height: 53rem;
      &__header {
        margin-bottom: 2rem;
        .logo {
          width: 4.4rem;
          height: 4.8rem;
          margin-right: 3rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__body {
        .description {
          margin-bottom: 2rem;
          width: 64rem;
          @include respond(phone-x-small) {
            width: 57rem;
          }
          h2 {
            font-size: 2.5rem;
            font-family: $font-secondary-bold;
            margin-bottom: 0.2rem;
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            opacity: 0.8;
            line-height: 2.2rem;
          }
        }
        .para {
          font-size: 1.6rem;
          font-family: $font-secondary-bold;
          margin-bottom: 2rem;
        }
        .badge-descriptoon {
          display: flex;
          align-items: center;
          margin-left: -1.2rem;
          .icon {
            font-size: 5.4rem;
            color: #ffa200;
          }
          p {
            font-size: 1.6rem;
            font-family: $font-secondary;
            width: 29rem;
            line-height: 2.2rem;
          }
        }
        .epb-btn {
          width: 31rem;
          font-size: 1.6rem;
          font-family: $font-secondary-bold;
          padding: 1.2rem 2rem;
          height: 5.1rem;
          margin-top: 4rem;
        }

        .badge-user {
          position: relative;
          width: fit-content;
          @include respond(phone-large) {
            display: none;
          }
          .user-image {
            width: 7.8rem;
            height: 7.8rem;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }
          .badge {
            width: 3.4rem;
            height: 3.4rem;
            border-radius: 100%;
            background-color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0.2rem;
            top: 5.2rem;
            .icon {
              font-size: 2rem;
              color: #ffa200;
            }
          }
        }
        .user-1 {
          top: -26rem;
          right: -69rem;
        }
        .user-2 {
          top: -22rem;
          right: -52rem;
        }
        .user-3 {
          top: -28rem;
          right: -67rem;
        }
        .user-4 {
          top: -26rem;
          right: -59rem;
        }
        .user-5 {
          top: -32rem;
          right: -72.5rem;
        }
        .user-6 {
          top: -38rem;
          right: -43rem;
        }
      }
    }
  }
}
</style>
