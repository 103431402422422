<template>
  <h1 class="heading">Welcome to your Financial Dashboard</h1>
  <div class="stats-card-main">
    <stats-card></stats-card>
    <stats-card></stats-card>
    <stats-card></stats-card>
    <stats-card></stats-card>
    <stats-card></stats-card>
  </div>
  <a-row :gutter="[24, 24]">
    <a-col :xs="24" :sm="24" :md="14" :lg="14">
      <monthly-patron></monthly-patron>
    </a-col>
    <a-col :xs="24" :sm="24" :md="10" :lg="10">
      <article-performing-card></article-performing-card>
      <article-performing-card></article-performing-card>
    </a-col>
  </a-row>
</template>
<script>
import StatsCard from "../FinancialDashboard/StatsCard.vue";
import MonthlyPatron from "../FinancialDashboard/MonthlyPatron.vue";
import ArticlePerformingCard from "../FinancialDashboard/ArticlePerformingCard.vue";
export default {
  components: {
    StatsCard,
    MonthlyPatron,
    ArticlePerformingCard,
  },
};
</script>
<style lang="scss" scoped>
.heading {
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-family: $font-primary-bold;
}

.stats-card-main {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2rem;
  margin-bottom: 3.3rem;
  @include respond(laptop-small) {
    grid-template-columns: auto auto;
  }
  @include respond(tab-port) {
    grid-template-columns: auto auto;
  }
  @include respond(phone-large) {
    grid-template-columns: auto;
  }
  @include respond(phone-x-small) {
    grid-template-columns: auto;
  }
}
</style>