<template>
  <div class="stats-card">
    <div class="stats-card__detail">
      Your Earnings on Glocal<br />
      <span class="stats-card__total">$ 10500.00</span>
    </div>
    <div class="graph">
      <img src="../../assets/images/Group1370@2x.png" alt="" class="image" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.stats-card {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3rem;
  height: auto;
  padding: 1.5rem;
  background-color: $color-white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  //   max-width: 20.9rem;
  &__detail {
    font-size: 1.44rem;
    width: 100%;
    .stats-card__total {
      font-size: 2rem;
      font-family: $font-secondary-bold;
    }
  }
  .graph {
    width: 15rem;
    height: auto;
    .image {
      width: 100%;
    }
  }
}
</style>