<template>
  <div class="monthly-patron">
    <div class="monthly-patron__header">
      <h2>Monthly Patrons</h2>
    </div>
    <div class="monthly-patron__body">
      <monthly-patron-list-item></monthly-patron-list-item>
      <monthly-patron-list-item></monthly-patron-list-item>
      <monthly-patron-list-item></monthly-patron-list-item>
      <monthly-patron-list-item></monthly-patron-list-item>
      <monthly-patron-list-item></monthly-patron-list-item>
      <monthly-patron-list-item></monthly-patron-list-item>
      <the-pagination></the-pagination>
    </div>
  </div>
</template>
<script>
import MonthlyPatronListItem from "../FinancialDashboard/MonthlyPatronListItem.vue";
import ThePagination from "../BaseComponents/ThePagination.vue";

export default {
  components: {
    MonthlyPatronListItem,
    ThePagination,
  },
};
</script>
<style lang="scss" scoped>
@include reset-css;

.monthly-patron {
  width: 100%;
  &__header {
    padding: 1.5rem;
    background-color: $color-dark-blue-2;
    margin-bottom: 1.3rem;
    h2 {
      font-size: 1.8rem;
      font-family: $font-secondary-bold;
      color: $color-white;
    }
  }
  &__body {
    padding: 2rem 1.5rem;
    background-color: $color-white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .pagination {
        padding: 1rem 0 !important;
        padding-bottom: 0.5rem !important;
    }
  }
}
</style>