<template>
  <div class="monthly-patron-list-item">
    <div class="monthly-patron-list-item__left">
      <div class="user-image">
        <img src="../../assets/images/Selena-Gomez-Square.webp" alt="" />
      </div>
      <h4>Selena Gomez</h4>
      <div class="company">
        <i class="icon icon-bookmark"></i>
        <p>Mckinsey & Company</p>
      </div>
    </div>
    <div class="monthly-patron-list-item__right">
      <button>Message</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include reset-css;
.monthly-patron-list-item {
  width: 100%;
  background-color: $color-light-grey-2;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  &__left {
    display: flex;
    align-items: center;
    .user-image {
      width: 4rem;
      height: auto;
      border: 0.24rem solid $color-white;
      margin-right: 2rem;
      img {
        width: 100%;
      }
    }
    h4 {
      font-size: 1.6rem;
      font-family: $font-primary-bold;
    }
    .company {
      display: flex;
      align-items: center;
      margin-left: 4rem;
      @include respond(phone-x-small) {
        margin-left: 1rem;
      }
      .icon {
        font-size: 1.6rem;
        margin-right: 0.8rem;
      }
      p {
        font-size: 0.9rem;
        font-family: $font-primary-bold;
      }
    }
  }
  &__right {
    button {
      border: none;
      font-size: 1.2rem;
      font-family: $font-primary-semibold;
      width: 15.5rem;
      padding: 1rem 0;
      cursor: pointer;
      background-color: $color-primary;
      color: $color-white;
      @include respond(phone-large) {
        width: 10rem;
      }
    }
  }
}
</style>