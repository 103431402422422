<template>
  <the-financial-dashboard></the-financial-dashboard>
</template>

<script>
import TheFinancialDashboard from "../components/FinancialDashboard/TheFinancialDashboard.vue";

export default {
  components: {
    TheFinancialDashboard,
  },
};
</script>