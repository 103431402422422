<template>
  <div class="articles">
    <a-row :gutter="[18, 18]">
      <a-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="6"
        v-for="article in articles"
        :key="article.id"
      >
        <article-card
          :title="article.title"
          :author-name="article.authorName"
          :image="article.image"
          :profile-photo="article.profilePhoto"
        ></article-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ArticleCard from "./FundraiserArticleCard.vue";
// import ArticleCard from "../BaseComponents/ArticleCard.vue";

export default {
  components: {
    ArticleCard,
  },
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Olympics ygytyy - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
        {
          id: 2,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
        {
          id: 3,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
        {
          id: 4,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: "profile-pic.webp",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.articles {
  position: relative;
  z-index: 20000;
  padding-right: 2rem;
}
</style>
