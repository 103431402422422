<template>
  <div class="item">
    <div class="user-profile">
      <div class="user-img">
        <img src="../../assets/images/Selena-Gomez-Square.webp" alt="User" />
      </div>
      <div class="user-name">
        <h4>Selena Gomez</h4>
        <p>New York, United States</p>
      </div>
      <div class="user-des">
        <h4>Donation Capacity</h4>
        <p>Copy Writing</p>
      </div>
      <div class="user-des">
        <h4>One line Description</h4>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing</p>
      </div>
      <div class="user-message">
        <button class="message">Message</button>
        <i class="icon icon-vertical_three-dots"></i>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 2.5rem 0;
  border-bottom: 0.24rem solid rgba(128, 128, 128, 0.096);
  .user-profile {
    display: flex;
    align-items: center;
    width: 100%;
    // position: relative;
    .user-img {
      width: 4.7rem;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    .user-name {
      margin-left: 1rem;
      margin-right: 2rem;
      width: auto;
      h4 {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
      }
      p {
        font-size: 1.2rem;
        font-family: $font-primary;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
    .user-des {
      margin-left: 5rem;
      width: auto;
      h4 {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
      }
      p {
        font-size: 1.2rem;
        font-family: $font-primary;
        margin-bottom: 0;
      }
    }
    .user-message {
      display: flex;
      align-items: center;
      margin-left: auto;
      .message {
        height: 4rem;
        font-size: 1.3rem;
        border: 0.23rem solid $color-primary;
        color: $color-primary;
        padding: 0 3rem;
        font-family: $font-primary-semibold;
        background-color: transparent;
        cursor: pointer;
        transition: 0.3s;
        margin-left: 0.5rem;
      }
      .message:hover {
        color: $color-white;
        background-color: $color-primary;
        border-color: $color-white;
      }
      .icon {
        padding-left: 1.5rem;
        font-size: 2.5rem;
        cursor: pointer;
      }
    }
  }
}
</style>