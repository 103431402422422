<template>
  <a-drawer
    placement="right"
    :closable="true"
    :visible="showDrawer"
    @close="$emit('close-drawer')"
    width="71%"
    wrapClassName="donations-received"
  >
    <div class="donations-received__header">
      <h2>Donations Received</h2>
      <span
        @click="select('donors-analysis')"
        :class="{ active: isActive('donors-analysis') }"
        >Donors and Analysis</span
      >
      <span
        @click="select('effort-donor')"
        :class="{ active: isActive('effort-donor') }"
        >Effort Donors</span
      >
    </div>
    <div
      v-if="selectedTab == 'donors-analysis'"
      class="donations-received__body"
    >
      <div class="header">
        <a href="" class="active">Donors and Analysis</a>
        <a href="">Effort Donors</a>
      </div>
      <div class="body">
        <div class="stats-card-main">
          <stats-card></stats-card>
          <stats-card></stats-card>
          <stats-card></stats-card>
          <stats-card></stats-card>
          <stats-card></stats-card>
        </div>
        <a-row :gutter="18">
          <a-col :xs="24" :sm="24" :md="12" :lg="12">
            <div class="body__left">
              <h2>Actions</h2>
              <button class="btn-1">Add another Round for Donations</button>
              <button class="btn-2">Close Donations</button>
              <button class="btn-3">Withdraw payments</button>
              <h2>Withdrawal Disclaimer</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </p>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="12">
            <div class="body__right">
              <h2>List Of Donors</h2>
              <list-of-donors></list-of-donors>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div
      v-else-if="selectedTab == 'effort-donor'"
      class="donations-received__body effort-donors"
    >
      <div class="header">
        <a href="" class="active">Donation Round 1</a>
        <a href="">Donation Round 2</a>
      </div>
      <div class="body">
        <div class="stats-card-main">
          <stats-card></stats-card>
          <stats-card></stats-card>
          <stats-card></stats-card>
          <stats-card></stats-card>
          <stats-card></stats-card>
        </div>
        <a-row :gutter="18">
          <a-col :xs="24" :sm="24" :md="24" :lg="24">
            <div class="list-of-donors">
              <h2>List Of Donors</h2>
              <div class="list-of-donors__body">
                <the-donors></the-donors>
                <the-donors></the-donors>
                <the-donors></the-donors>
                <the-donors></the-donors>
                <the-donors></the-donors>
                <the-donors></the-donors>
                <the-donors></the-donors>
                <the-donors></the-donors>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import StatsCard from "../FinancialDashboard/StatsCard.vue";
import ListOfDonors from "../FinancialDashboard/ListOfDonors.vue";
import TheDonors from "./TheDonors.vue";

export default {
  components: {
    StatsCard,
    ListOfDonors,
    TheDonors,
  },
  props: {
    showDrawer: Boolean,
    fundraiser: Boolean,
  },
  data() {
    return {
      selectedTab: "donors-analysis",
    };
  },
  methods: {
    select(tab) {
      // console.log(tab);
      this.selectedTab = tab;
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
  },
};
</script>
<style lang="scss">

.donations-received {
  @include reset-css;
  .ant-drawer-content-wrapper {
    box-shadow: -17px 3px 94px #0000001a;
  }
  .ant-drawer-wrapper-body {
    background-color: #0000001a !important;
  }
  .ant-drawer-close {
    top: 5rem;
    right: 2rem;
  }
  .ant-drawer-body {
    padding: 0;
  }
  &__header {
    padding: 0rem 4rem;
    padding-top: 7rem;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    h2 {
      font-size: 2.4rem;
      font-family: $font-secondary-bold;
      font-weight: 900;
      margin-right: 4rem;
    }
    span {
      font-size: 1.8rem;
      font-family: $font-secondary;
      margin-right: 2rem;
      color: $color-black;
      opacity: 0.5;
      cursor: pointer;
    }
    .active {
      font-weight: 900;
      font-family: $font-secondary-bold;
      opacity: 1;
    }
  }
  &__body {
    padding: 4rem;
    padding-top: 3rem;
    .header {
      padding-top: 7rem;
      display: flex;
      align-items: center;
      padding-top: 0;
      a {
        font-size: 1.8rem;
        font-family: $font-secondary;
        margin-right: 2rem;
        color: $color-black;
        opacity: 0.5;
      }
      .active {
        font-weight: 900;
        font-family: $font-secondary-bold;
        opacity: 1;
      }
    }
    .body {
      margin-top: 3rem;
      .stats-card-main {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 2rem;
        margin-bottom: 3.5rem;
        @include respond(laptop-small) {
          grid-template-columns: auto auto;
        }
        @include respond(tab-port) {
          grid-template-columns: auto auto;
        }
        @include respond(phone-large) {
          grid-template-columns: auto;
        }
        @include respond(phone-x-small) {
          grid-template-columns: auto;
        }
      }
      &__left {
        padding-right: 12rem;
        @include respond(laptop-small) {
          padding-right: 0;
        }
        h2 {
          font-size: 1.8rem;
          font-family: $font-secondary-bold;
          font-weight: 900;
          margin-bottom: 1rem;
        }

        button {
          border: none;
          font-size: 1.8rem;
          font-family: $font-secondary-bold;
          font-weight: 900;
          width: 100%;
          padding: 0.5rem 0;
          margin-top: 1.6rem;
          cursor: pointer;
          color: $color-white;
          height: 6rem;
        }
        button:hover {
          opacity: 0.8;
        }
        .btn-1 {
          background-color: $color-faded-blue;
          //   margin-top: 0.5rem;
        }
        .btn-2 {
          background-color: #d1600b;
        }
        .btn-3 {
          background-color: #001b26;
          margin-bottom: 2.5rem;
        }
        p {
          font-size: 1.6rem;
          font-family: $font-secondary;
          margin-bottom: 3rem;
        }
      }
      &__right {
        h2 {
          font-size: 1.8rem;
          font-family: $font-secondary-bold;
          font-weight: 900;
        }
      }
    }
  }
  .effort-donors {
    .list-of-donors {
      h2 {
        font-size: 1.8rem;
        font-family: $font-secondary-bold;
        font-weight: 900;
      }
      &__body {
        height: 48rem;
        overflow-y: scroll;
        margin-top: 1.6rem;
        padding-right: 2rem;
      }
      &__body::-webkit-scrollbar {
        width: 0.5rem;
      }

      &__body::-webkit-scrollbar-track {
        background-color: #c8c8c87d;
        border-radius: 0.8rem;
      }

      &__body::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 0.8rem;
        height: 5rem;
      }
    }
  }
}
</style>
